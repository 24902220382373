import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { airDistance } from "../components/helpers/truncate";
import { GET_ADDRESS_FROM_COORDINATES } from "../configs";
import { API_URL } from "../configs/website";
import LocationPage from "./LocationPage";
function AutoLocation({ updateStatus, locationUpdate }) {
  const user = useSelector((state) => state.user.user);
  const checkCurrentCity = () => {
    try {
      axios
        .post(
          user.success ?
            API_URL + "/user-city-check-auth"
            :
            API_URL + "/user-city-check",
          {
            latitude: JSON.parse(localStorage.getItem("userSetAddress")).lat,
            longitude: JSON.parse(localStorage.getItem("userSetAddress")).lng,
            token: user && user.success ? user?.data?.auth_token : null
          })
        .then((response) => {
          try {
            const city = response.data;

            localStorage.setItem("last_city_api", new Date());
            localStorage.setItem("city", JSON.stringify(city.city));
            localStorage.setItem("city_is_active", JSON.stringify(city.is_active));
            localStorage.setItem("city_listed", JSON.stringify(city.city_listed));

            const address = JSON.parse(localStorage.getItem("userSetAddress"));
            address.city = JSON.stringify(city.city);
            localStorage.setItem("userSetAddress", JSON.stringify(address));
            setTimeout(() => {
              if (updateStatus) updateStatus();
            }, 0);
          } catch (error) {
            setTimeout(() => {
              if (updateStatus) updateStatus();
            }, 0);
          }
        });
    } catch (error) {
    }
  };
  const [GPSLoading, setGPSLoading] = useState(false);
  const [tooLong, setTooLong] = useState(false);
  const handleLocationWaiting = () => {
    setTimeout(() => {
      setTooLong(true);
    }, 8000);
  }
  const handleGeoLocationClick = (results) => {
    const userSetAddress = {
      lat: results[0].geometry.location.lat,
      lng: results[0].geometry.location.lng,
      address: results[0].formatted_address,
      house: null,
      tag: null,
    };

    const radius = airDistance(userSetAddress.lat, userSetAddress.lng, user?.data?.default_address?.latitude, user?.data?.default_address?.longitude);
    // console.log(radius, 'radius @ auto location');

    const saveUserSetAddress = new Promise((resolve) => {
      if (radius >= 1000)
        localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
      resolve("Address Saved");
    });
    saveUserSetAddress.then(() => {
      localStorage.setItem("autoLocation", true);
      localStorage.setItem("userSelected", "DELIVERY");
      checkCurrentCity();
    });
  };
  const reverseLookup = (lat, lng) => {
    axios.post(GET_ADDRESS_FROM_COORDINATES, {
      lat: lat,
      lng: lng,
    })
      .then((response) => {

        const myLocation = [
          {
            formatted_address: response.data,
            geometry: {
              location: {
                lat: lat,
                lng: lng,
              },
            },
          },
        ];
        handleGeoLocationClick(myLocation);
      });
  };
  const getMyLocation = () => {
    try {
      const address = JSON.parse(localStorage.getItem("userSetAddress"));
      if (!address?.lat) {
        setTimeout(() => {
          if (updateStatus) updateStatus();
        }, 0);
      }
    } catch (error) {
      setTimeout(() => {
        if (updateStatus) updateStatus();
      }, 0);
    }
    setGPSLoading(true)
    handleLocationWaiting();
    setTimeout(() => {
      try {
        const location = navigator && navigator.geolocation;
        if (window.ReactNativeWebView) {
          const response = {
            type: 'location_permission',
            data: 'no-data'
          };
          window.ReactNativeWebView.postMessage(JSON.stringify(response));
        }
        if (location) {
          location.getCurrentPosition(
            (position) => {
              reverseLookup(position.coords.latitude, position.coords.longitude);
            },
            (error) => {
              alert(localStorage.getItem("gpsAccessNotGrantedMsg"));
            }
          );
        }
      } catch (error) {
        alert(localStorage.getItem("gpsAccessNotGrantedMsg"));
      }
    }, 0);
  };
  useEffect(() => {
    getMyLocation();
  }, []);
  return (
    <React.Fragment>
      <LocationPage />
    </React.Fragment>
  );
}

export default AutoLocation;
