import React, { useEffect } from "react";
function LocationPage() {
  useEffect(() => {
    try {
      if (window.ReactNativeWebView) {
        const response = {
          type: 'address_data',
          data: JSON.parse(localStorage.getItem("userSetAddress"))
        };
        window.ReactNativeWebView.postMessage(JSON.stringify(response));
      }
      if (document.ReactNativeWebView) {
        const response = {
          type: 'address_data',
          data: JSON.parse(localStorage.getItem("userSetAddress"))
        };
        document.ReactNativeWebView.postMessage(JSON.stringify(response));
      }
    } catch (error) {
    }
  }, []);
  
  return (
    <React.Fragment>
    </React.Fragment>
  );
}

export default LocationPage;
