import React, { Component } from "react";
import { Oval, ThreeDots } from "react-loader-spinner";
import { setWhatsAppChatButton } from "./truncate";
class Loading extends Component {
  state = {
    locationStored: false
  }
  componentDidMount() {
    setWhatsAppChatButton(false);
    try {
      const address = JSON.parse(localStorage.getItem("userSetAddress"));
      if (address.lat) {
        this.setState({
          locationStored: true
        });
      } else {
        this.setState({
          locationStored: false
        });
      }
    } catch (error) {
      this.setState({
        locationStored: false
      });
    }
  }

  render() {
    const { checkLocation } = this.props;
    return (
      this.state.locationStored && checkLocation ? <></> :
      <React.Fragment>
        <div
          style={{
            position: "absolute",
            zIndex: 99995,
            background: '#ffffffdb',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}>
          <Oval
            height={45}
            width={45}
            color="#b5b5b5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#b5b5b5"
            strokeWidth={2}
            strokeWidthSecondary={2} />
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              color: '#121212',
              fontWeight: '500',
              fontSize: '0.89rem'
            }}>
            <span>Loading</span>
            <span className="pl-2">
              <ThreeDots
                color="#000000"
                height={18}
                width={18}
                visible={true}
              />
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Loading;
