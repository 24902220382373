export const GET_QUICK_BITES = "GET_QUICK_BITES";
export const GET_ITEM_LIST = "GET_ITEM_LIST";
export const GET_TAG_SLIDERS = "GET_TAG_SLIDERS";
export const GET_STORY_CLUB_RESTAURANT = "GET_STORY_CLUB_RESTAURANT";
export const SET_PAGE_USER_COUNT = "SET_PAGE_USER_COUNT";
export const GET_FLASH_DEAL_ITEMS = "GET_FLASH_DEAL_ITEMS";
export const GET_FLASH_DEAL_USERS = "GET_FLASH_DEAL_USERS";
export const GET_OFFER_PERCENTAGE_ITEMS = "GET_OFFER_PERCENTAGE_ITEMS";
export const GET_OFFERS_LIST_ITEMS = "GET_OFFERS_LIST_ITEMS";
export const GET_OFFER_PERCENTAGE_ITEMS_ITEM = "GET_OFFER_PERCENTAGE_ITEMS_ITEM";
export const GET_OFFER_AMOUNT_ITEMS = "GET_OFFER_AMOUNT_ITEMS";
export const GET_OFFER_AMOUNT_ITEMS_ITEM = "GET_OFFER_AMOUNT_ITEMS_ITEM";
export const GET_COUPON_RESTAURANT = "GET_COUPON_RESTAURANT";
export const GET_COUPON_ITEMS = "GET_COUPON_ITEMS";
export const GET_CITY_STORE_TYPES = "GET_CITY_STORE_TYPES";
export const GET_COMING_SOON_DATA = "GET_COMING_SOON_DATA";
export const SET_COMING_SOON_NOTIFY_DATA = "SET_COMING_SOON_NOTIFY_DATA";
export const GET_AD_BANNER_DATA = "GET_AD_BANNER_DATA";
export const GET_YUMMY_ITEMS_DATA = "GET_YUMMY_ITEMS_DATA";
export const GET_YUMMY_ITEMS_FRESH_DATA = "GET_YUMMY_ITEMS_FRESH_DATA";
export const GET_YUMMY_ITEMS_IFTAR_DATA = "GET_YUMMY_ITEMS_IFTAR_DATA";
export const GET_LUNCH_BOX_RESTAURANT = "GET_LUNCH_BOX_RESTAURANT";
export const GET_HOME_FEATURED_RESTAURANT = "GET_HOME_FEATURED_RESTAURANT";